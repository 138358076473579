<template>
  <DataList
    resource="users"
    :headers="headers"
    title="Список пользователей"
    disable-adding
    store="users"
    disable-compact-mode
    disable-sort
    disable-filter
  />
</template>

<script>
import DataList from "../../../components/DataList.vue";

export default ({
  components: {
    DataList,
  },
  data() {
    return {
      headers: [
        { text: "id", name: "id", sortable: false },
        { text: "Логин", name: "name", sortable: false },
        { text: "Статус", name: "isBlocked", sortable: false },
      ],
    };
  },
});
</script>
